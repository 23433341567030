import axios from 'axios'
import config from './config.js'
const request = axios.create({
  baseURL: config.host
})
// request.interceptors.request.use((res) => {
//   window.$Ycom.$emit('y-com-loading-chageShow', [true]) // loading开始
//   return res
// })
request.interceptors.response.use((res) => {
  setTimeout(() => {
    window.$Ycom.$emit('y-com-loading-chageShow', [false]) // loading结束
    if (res.data.code !== 200) {
      // code要和后端确定，path
      window.$Ycom.$emit('y-com-newtip-addone', ['请求失败' + res.data.msg, 5000, 'err'])
    }
  }, 200)
  return res
})
export default request
