<template>

<div class="big">
<div class="w">
<div class="img" :style="{ left: -data.active * data.liwidth + 'px' }">
<ul
          ref="imageboxdom"
          v-for="(item, index) in data.imaglist"
          :key="item.id + 'x' + index"
        >
<li>
<div
              class="vague"
              :style="{ background: `url(${data.baseUrl}${item.pictureUrl})` }"
            ></div>
<div class="external">
<img
                :src="data.baseUrl + item.pictureUrl"
                alt=""
                @click="modulepage(item)"
              />
</div>
</li>
</ul>
</div>
</div>
<div class="l" v-if="data.active !== 0" @click="data.active -= 1"></div>
<div
      class="r"
      v-if="data.imaglist.length - 1 !== data.active"
      @click="data.active += 1"
    ></div>
</div>
</template>
<script>
import { useRouter } from "vue-router";
import { home1showPictures } from "../../../api/compoents.js";
import {
  nextTick,
  onMounted,
  reactive,
  ref,
  onUpdated,
  onUnmounted,
} from "vue";

export default {
  name: "AppSwiper",
  setup() {
    const router = useRouter();
    const imageboxdom = ref(null);
    const data = reactive({
      //baseUrl: "http://39.103.190.34:8181",
      baseUrl:'http://59.110.106.225:8181',
      imaglist: [],
      active: 0,
      liwidth: 0,
      loadFinish: false,
    });
    let timer = "";
    let timerNum = 1;

    // 页面渲染
    async function gethome1showPictures() {
      const { data: res } = await home1showPictures();
      console.log("轮播图？", res);
      data.imaglist = res.allPicByIsShow;
    }

    // 跳转
    function modulepage(i) {
      console.log(i);
      
      if (i.link === null || i.link === "") {
        const href = router.resolve({
          // 传递参数使用query的话，指定path或者name都行，但使用params的话，只能使用name指定
          path: "/page",
          query: {
            id: i.pictureId,
            names: "轮播图",
            logn: "B",
          },
        });
        window.open(href.href, "_blank");
      }else{
        window.open(i.link, "outside");
      }
    }

    // 切换颜色
    function chageLight() {
      window.$Ycom.$emit("y-com-control-chagelight");
    }

    onUpdated(() => {
      if (data.loadFinish) {
        data.liwidth = imageboxdom.value[0].offsetWidth;
      }
    });
    onMounted(async () => {
      data.loadFinish = false;
      await gethome1showPictures();
      data.loadFinish = true;
      nextTick(() => {
        // nextTick页面下一次渲染
        data.liwidth = imageboxdom.value[0].offsetWidth;
        timer = setInterval(() => {
          if (data.active <= 0) timerNum = 1;
          if (data.active >= data.imaglist.length - 1) timerNum = -1;
          data.active += timerNum;
        }, 10000);
      });
    });
    onUnmounted(() => {
      clearInterval(timer);
    });
    return {
      data,
      imageboxdom,
      modulepage,
      chageLight,
    };
  },
};
</script>
<style scoped lang="less">
@media screen and (max-width: 767px) {
  .big {
    z-index: 55;
    position: absolute;
    top: calc(var(--sw) * -0.001);
    //top: calc(var(--sw) * 11);
    .img ul li {
      height: calc(var(--sw) * 0.8);
    }

    .box1 {
      height: calc(var(--lw) * 0.1);
      line-height: calc(var(--lw) * 0.1);
    }

    .l {
      transform: scale(0.6);
    }

    .r {
      transform: scale(0.6);
    }
    .vague {
      display: none;
    }
    .external {
      width: 100vw;
      height: calc(var(--s33w) * 0.8);
    }
  }
}

.big {
  --sw: calc(var(--xd--fontsize) * 12);
  position: relative;
  width: 100vw;
  //margin: calc(var(--xd--fontsize) * 2) auto;
  border-radius: var(--y-com-radius0);
  box-shadow: var(--shadow);
  //top:calc(var(--xd--fontsize) * 4);
  
}
@media screen and (min-width: 1200px) {
  .big {
    top: 8px;
  }
  .vague {
    position: absolute;
    width: 100vw;
    height: calc(var(--sw) * 1.2);
    background-repeat: no-repeat !important;
    background-size: 100vw calc(var(--sw) * 1.2) !important;
    filter: blur(20px);
    transform: scale(1.2);
    z-index: 0;
  }
  .external {
    width: 81vw;
    height: calc(var(--sw) * 1.2);
    margin: 0 auto;
    img {
      position: relative;
      z-index: 100;
    }
  }

  //.l {
  //  z-index: 999;
  //  left: 11% !important;
  //}
  //.r {
  //  z-index: 999;
  //  right: 11% !important;
  //}
}
.w {
  width: 100%;
  overflow: hidden;
  //border-radius: var(--y-com-radius0);
  // background: #000;
}

.img {
  display: flex;
  position: relative;
  transition: all 0.3s;
  border-radius: var(--y-com-radius0);
}

.img {
  & > ul {
    padding-right: 4vmax;
    display: flex;
  }
}
.img ul {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.img ul li {
  position: relative;
  --lw: calc(var(--xd--fontsize) * 24);
  width: 100vw;
  height: calc(var(--sw) * 1.2);
  overflow: hidden;
  box-shadow: var(--shadow);
}

.img ul li img {
  text-align: center;
  width: 100%;
  height: 100%;
  // 上面三行代码实现图片等比例居中在盒子中
}

.box1 {
  --lw: calc(var(--xd--fontsize) * 10);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(var(--lw) * 0.2);
  line-height: calc(var(--lw) * 0.2);
  padding: 0 calc(var(--lw) * 0.1);
  // line-height:calc(var(--sw)*.1) ;
  backdrop-filter: saturate(150%) blur(8px);
  -webkit-backdrop-filter: saturate(150%) blur(8px);
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  z-index: 1;
  font-size: calc(var(--xd--fontsize) * 0.5);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  // padding-right: calc(var(--lw)*1);
  // border-radius: var(--y-com-radius0);
}

// .box1 p{
//   text-align: center;
// }
.l {
  top: 50%;
  position: absolute;
  left: 2%;
  margin-top: -37px;
  width: 37px;
  height: 74px;
  background: url("../../../assets/img/icon-pre.png");
}

.r {
  top: 50%;
  position: absolute;
  right: 2%;
  margin-top: -37px;
  width: 37px;
  height: 74px;
  background: url("../../../assets/img/icon-next.png");
}
</style>
