<template>
<div class="box">
<comContril></comContril>
<newtip></newtip>
</div>
<loading></loading>

<div class="App-appMain" y-side>

<appHeader1></appHeader1>

<router-view/>
</div>
</template>

<script>
import comContril from './y-com/YcomControl/YcomControl.vue'
import newtip from './y-com/YcomNewtip/YcomNewtip.vue'
import loading from './y-com/Ycomloading/Ycomloading.vue'
import appHeader1 from './views/home/components/header2.vue'
let first = true
export default {
  name: 'myApp',
  components: {
    comContril,
    newtip,
    loading,
    // appHeader,
    appHeader1
  },
  methods: {
    bodyfun () {

      if (first) {
        setTimeout(() => {
          window.$Ycom.$emit('y-com-newtip-addone', ['欢迎使用', 5000, 'normal'])
        }, 4000)
        first = false
      }
    }
  },
  mounted () {
    document.querySelector('body').addEventListener('click', this.bodyfun)
  },
  unmounted () {
    document.querySelector('body').removeEventListener('click', this.bodyfun)
  },

}
</script>

<style lang="less">
body {
   background: #fff!important;
}


.box{
  display: none;
  position: relative;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.App-appMain{
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: auto;
}
</style>
