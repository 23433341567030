<template>

<div v-show="data.show" class="y-com-control" theme y-flexY>
<div class="icon" theme2></div>
<ul y-flexY>
<li y-flexY>
<div class="theme" @click.stop="handlerChageLight"><YcomLogo><div class="white" y-flexX theme >{{locData.light + '' === '2' ? '跟随' : '主题' +  (locData.light-0+1)}} </div></YcomLogo></div>
</li>

<li y-flexY>
<div class="soundWrap" @click.stop="handlerChageSound">
<div :class="data.sound ? 'sound soundmove': 'sound'" y-flexX >
<div Y-flexX  theme class="soundcontent" :class="data.sound ? '': 'nosound'">{{data.sound ? '声音' : '静音'}}</div>
</div>
</div>
</li>

</ul>
</div>

</template>

<script>
import YcomLogo from '../../y-com/YcomLogo/YcomLogo.vue'
import { shallowReactive, watch, onMounted, onUnmounted, reactive } from 'vue'
export default {
  name: 'YcomControl',
  setup () {
    const locData = reactive(JSON.parse(window.localStorage.getItem('y-com-setData2')) || {
      light: 0,
      sound: false,
      show: true,
      leftLight: true
    })
    const data = shallowReactive(locData)
    function handlerChageLight () {
      window.$Ycom.$emit('y-com-control-chagelight')
    }
    function chageLight () {
      data.light++
      if (data.light > 2) data.light = 0
    }
    function handlerChageSound () {
      window.$Ycom.$emit('y-com-control-chageSound')
    }
    function chageSound () {
      data.sound = !data.sound
    }

    watch(data, (newval) => {
      if (newval.light === 0) document.querySelector('body').className = 'y-com-light'
      // if (newval.light === 1) document.querySelector('body').className = 'y-com-light y-com-light1'
      // if (newval.light === 2) document.querySelector('body').className = 'y-com-light y-com-light2'
      // if (newval.light === 3) document.querySelector('body').className = 'y-com-light y-com-light3'
      if (newval.light === 1) document.querySelector('body').className = 'y-com-dark'
      if (newval.light === 2) {
        if (window.matchMedia('(prefers-color-scheme: light)').matches) document.querySelector('body').className = 'y-com-light'
        else document.querySelector('body').className = 'y-com-dark'
      }
      // if (newval.light === 2) document.querySelector('body').className = 'y-com-dark'
      //   if (newval.light === 5) document.querySelector('body').className = 'y-com-dark y-com-dark1'
      // if (newval.light === 6) document.querySelector('body').className = 'y-com-dark y-com-dark2'
      //   if (newval.light === 7) document.querySelector('body').className = 'y-com-dark y-com-dark3'
      window.localStorage.setItem('y-com-setData2', JSON.stringify(newval))
    }, { immediate: true })

    onMounted(() => {
      window.$Ycom.$on('y-com-control-chagelight', chageLight)
      window.$Ycom.$on('y-com-control-chageSound', chageSound)
    })
    onUnmounted(() => {
      window.$Ycom.$c('y-com-control-chagelight', chageLight)
      window.$Ycom.$c('y-com-control-chageSound', chageSound)
    })
    return {
      data,
      handlerChageSound,
      handlerChageLight,
      locData
    }
  },
  components: {
    YcomLogo
  }
}
</script>

<style scoped>

.y-com-control{
  user-select: none;
  --w: 7vmin;
  --w2:15vmin;
  --h2:40vmin;
  position: fixed;
  right: .4%;
  top: 99%;
  transform: translate(0,-100%);
  z-index: 10;
  width: var(--w);
  height: var(--w);
  font-size: 2vmin;
  font-weight: var(--y-com-fontWeight) ;
  border-radius: var(--y-com-radius0);
  overflow: hidden;
  transition: var(--y-com-transition0);
  user-select: none !important;
  
  border: rgba(128, 128, 128, 0.348) 2px solid;
  box-sizing: border-box;
}
@media screen and (max-width: 800px) {
  .y-com-control {
  --w:10vmin !important;
  --w2:23vmin !important;
  --h2:40vmin !important;
  }
}
.y-com-control:hover .icon{
  right: 160%;
}
.y-com-control:hover{
  width: var(--w2);
  height: var(--h2);
}
.y-com-control:hover ul li{
  opacity: 1;
}
.y-com-control ul li {
  border-bottom: var(--y-com-themeborderColor) 2px solid;
  flex: 1;
  width: 70%;
  height: 10vw;
  opacity: 0;
  transition: var(--y-com-transition0);
}
.y-com-control ul{
  display: flex;
  flex-shrink: 0;
  width: var(--w2);
  height: var(--h2);
}
.y-com-control ul li:nth-last-of-type(1){
  border: none !important;
}
.nosound{
  color: white !important;
  background-color: var(--y-com--theme) !important;
  opacity: 1;
}
.icon{
  width: var(--w2);
  height: 100%;
  border-radius: inherit;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%,-50%);
  transition: var(--y-com-transition0) .25s !important;
  z-index: 11;

}
.icon::after{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--w)/10*4);
  height: calc(var(--w)/10*4);
  background-color: rgba(255, 255, 255, 0.781);
  border-radius: 50%;
  box-shadow: inherit;
}
.icon::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--w)/10*6.5);
  height: calc(var(--w)/10*6.5);
  background-color: rgba(255, 255, 255, 0.395);
  border-radius: 50%;
  box-shadow: inherit;
}
.theme{
  width: calc(var(--w2) / 1.8);
  height: calc(var(--w2) / 1.8);
  cursor: pointer;
  transition: var(--y-com-transition0);
}
.soundWrap{
  transition: var(--y-com-transition0);
  width: calc(var(--w2) / 2.5);
  height: calc(var(--w2) / 2.5);
  border-radius: 50%;
  cursor: pointer;
}
.sound{
  width: 100%;
  height: 100%;
  font-size: var(--y-com-fontSize-common);
  border-radius: 50%;
  position: relative;
  transition: var(--y-com-transition0);
  background-color: var(--y-com--theme) !important;

}
.theme:hover, .soundWrap:hover{
  transform: scale(1.2);
}

.soundmove::after{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  background-color: var(--y-com--theme);
  z-index: -1;
  border-radius: inherit;
  animation: soundMove infinite var(--y-com-animation-time1);
}
.soundmove::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  background-color: var(--y-com--theme);
  z-index: -1;
  border-radius: inherit;
  animation: soundMove infinite var(--y-com-animation-time1) calc(var(--y-com-animation-time1)/3);
}

@keyframes soundMove {
  100%{
    width: 180%;
    height: 180%;
    opacity: 0;
  }
}
.white{
  width: 90%;
  height: 90%;
  background-color: #fff;
  border-radius: 50%;
  font-size: var(--y-com-fontSize-common);
  user-select: none !important;
  opacity: .85;
}
.soundcontent{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: .9;
}
</style>
