<template>

<div class="y-com-newtip" y-side>
<transition-group name="y-com-into">
<template v-for="i in data" :key="i.id">
<div class="y-com-newtipitem" theme
            y-flexX
        >
<div class="y-com-newTip-logoWrap">
<YcomLogo>
<div class="y-com-newTip-logo-title" v-if="i.type === 'normal'" theme y-flexX>系统</div>
<div class="y-com-newTip-logo-title " v-if="i.type === 'err'" theme y-flexX>警告</div>
<div class="y-com-newTip-logo-title " v-if="i.type === 'suc'" theme y-flexX>成功</div>
</YcomLogo>
</div>
<div class="y-com-newTip-text" y-flexY v-if="i.type === 'normal'">
<span class="y-com-newTip-text-title">系统通知</span>
<div class="y-com-newTip-text-normal">{{i.text}}</div>
</div>
<div class="y-com-newTip-text colorerr" y-flexY v-if="i.type === 'err'">
<span class="y-com-newTip-text-title">警告信息</span>
<div class="y-com-newTip-text-normal">{{i.text}}</div>
</div>
<div class="y-com-newTip-text colorsuc" y-flexY v-if="i.type === 'suc'">
<span class="y-com-newTip-text-title">成功信息</span>
<div class="y-com-newTip-text-normal">{{i.text}}</div>
</div>
<div y-com-delet @click="deletOne(i)"></div>
</div>
</template>
</transition-group>
<audio hidden controls :src="tip.tipsound" id="audio"></audio>
<audio hidden controls :src="tip.errsound" id="audio2"></audio>
<audio hidden controls :src="tip.sucsound" id="audio3"></audio>
</div>

</template>

<script>
import { onMounted, onUnmounted, reactive, shallowReactive } from 'vue'
import YcomLogo from '../YcomLogo/YcomLogo.vue'
// import YcomLogo from ''
export default {
  name: 'YcomNewtip',
  setup () {
    const data = reactive([
    ])
    let num = 0
    let playsound = JSON.parse(window.localStorage.getItem('y-com-setData2')) ? JSON.parse(window.localStorage.getItem('y-com-setData2')).sound : true
    const tip = shallowReactive({
      tipsound: require('@/y-com/YcomNewtip/tip.mp3'),
      errsound: require('@/y-com/YcomNewtip/9450.mp3'),
      sucsound: require('@/y-com/YcomNewtip/tip.mp3')
    })
    function addone (e) {
      const type = e.detail[2] || 'normal'
      const additem = {
        text: e.detail[0],
        id: num++,
        type: e.detail[2] || 'normal'
      }
      data.unshift(additem)
      if (type === 'normal' && playsound) {
        const _audio = document.getElementById('audio')
        _audio.currentTime = 0
        _audio.play()
      } else if (type === 'err' && playsound) {
        const _audio = document.getElementById('audio2')
        _audio.currentTime = 0
        _audio.play()
      } else if (type === 'suc' && playsound) {
        const _audio = document.getElementById('audio3')
        _audio.currentTime = 0
        _audio.play()
      }
      setTimeout(() => {
        for (let i = 0; i < data.length; i++) {
          try {
            if (data[i].id === additem.id) data.splice(i, 1)
          } catch {}
        }
      }, e.detail[1] || 5000)
    }

    function deletOne (obj) {
      for (let i = 0; i < data.length; i++) {
        try {
          if (data[i].id === obj.id) data.splice(i, 1)
        } catch {}
      }
    }
    function chageSound () {
      playsound = !playsound
    }
    onMounted(() => {
      window.$Ycom.$on('y-com-newtip-addone', addone)
      window.$Ycom.$on('y-com-control-chageSound', chageSound)
    })
    onUnmounted(() => {
      window.$Ycom.$c('y-com-newtip-addone', addone)
      window.$Ycom.$c('y-com-control-chageSound', chageSound)
    })
    return {
      data,
      tip,
      deletOne
    }
  },
  components: { YcomLogo }
}
</script>

<style scoped>
.y-com-newtip{
  --w: 50vmin;
  --h: 40vmin;
  position: fixed;
  top: 2vmin;
  right: .5%;
  width: var(--w);
  max-height: 100vh;
  z-index: 101;
  user-select: none;
  transition: var(--y-com-transition0);
}
.y-com-newtipitem{
  position: relative;
  z-index: 100;
  width: calc(var(--w) * 0.95);
  min-height: calc(var(--h)/3.4);
  margin: 0 0 1vw;
  border-radius: var(--y-com-radius0);
  
  border: rgba(128, 128, 128, 0.348) 2px solid;
  justify-content: flex-start;
  overflow: hidden;
}
.y-com-newtipitem:last-child{
  margin-bottom: 0;
}
.y-com-newTip-logoWrap{
  width: calc(var(--h)/5);
  height: calc(var(--h)/5);
  margin: 0 4vmin;
}
.y-com-newTip-text{
  min-height: 10vmin;
  width: calc(var(--w) * 0.92 - 8vmin - var(--h)/3.2);
  align-items: flex-start;
}
.y-com-newTip-text-normal{
  opacity: .9;
  font-weight: normal;
  white-space: pre-wrap;
  text-align: left;
}
.y-com-newTip-text-title{
  font-size: var(--y-com-fontSize-Big);
}
.y-com-newTip-logo-title{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: .85;
}
.y-com-into-leave-active {
  animation: ycominit2 .2s reverse;
}
.y-com-into-enter-active {
  animation: ycominit2 .2s;
}
@keyframes ycominit2 {
  0%{
    height: 0vw  !important;
    
    transform: scale(0) translateX(24vw);
    margin: -10% auto;
    padding: -100%;
    opacity: 0  !important;
    overflow: hidden;
  }
}
.colorerr{
  color: rgb(255, 95, 95) !important;
}
.colorsuc{
  color: #3ac32a !important;
}
[y-com-delet]{
  position: absolute;
  top: 20%;
  right: 5%;
  height: calc(var(--w) * .06);
  width: calc(var(--w) * .06);
  
  
}
</style>
