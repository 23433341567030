import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import appHome from '../views/home/home.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: appHome
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: appLogin
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/about/about.vue')
  },
  {
    path: '/page',
    name: 'page',
    component: () => import('../views/page/pages.vue')
  },
  {
    path: '/homepage',
    name: 'homePage',
    component: () => import('../views/page1/page1.vue')
  },
  {
    path: '/search',
    name: 'searchResult',
    component: () => import('../views/about/search.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
