import request from '../../utils/request.js'
export const home1show = () => {
  return request.get('/home1/show')
} // 导航条
export const home1showNa = (id) => {
  return request.get(`/home1/showNa/${id}`)
} // 导航条的二级分类
export const homelshowTi = (id) => {
  return request.get(`/home1/showTi/${id}`)
}// 首页模块内容
export const homeDynamic = () => {
  return request.get(`/home1/showPictures2`)
}// 首页学会动态内容
export const home1showPart = (id, pageNum = 1, pageSize = 10) => {
  return request.get(`/home1/showPart/${id}?pageNum=${pageNum}&pageSize=${pageSize}`)
}// 显示3级页面  pageNum当前页码数  pageSize一页显示多少条
export const showOthersTitle = () => {
  return request.get('/home1/showOthersTitle')
}//首页中间模块小类
export const showPartInform2 = ()=>{
  return request.get('/home1/showPartInform2')
} 
// 首页其他模块
export const getHomePic = () => {
  return request.get('/home1/getPicture')
} // 首页其他模块
export const showPartInform = () => {
  return request.get('/home1/showPartInform')
} // 首页新增的其他模块
export const home1showPictures = () => {
  return request.get('/home1/showPictures')
} // 轮播图
export const home1showTi = (id) => {
  return request.get(`/home1/showTi/${id}`)
} // 首页模块内容2级页面
export const homeshow = (id) => {
  return request.get(`/home1/show/${id}`)
} // 导航栏详情页
export const homeshow2 = (id) => {
  return request.get(`/home1/show2/${id}`)
} // 其他详情页
export const searchDetail = (id) => {
  return request.get(`/home1/getPartContent/${id}`)
} // 搜索标题详情
export const scan = () => {
  return request.get('/home1/showCounts')
} // 网站浏览量
export const showSearchResult = (inputValue,pageNum = 1,pageSize = 10) => {
  return request.get(`/home1/selectByKeywords?keywords=${inputValue}&pageNum=${pageNum}&pageSize=${pageSize}`)
} // 搜索


export const getDayViews = () => {
  return request.get('/home1/showCounts')
}
