<template>
<div class="w" theme>
<div class="up">
<div class="l1">信息化平台</div>
</div>
<div class="bottom">
<li >
<span class="iconfont" style= 'color: #f6d91a'>&#xe683;</span>
<a href="https://member.ysjszk.com/" target="_blank">会员系统</a>
</li>
<li >
<span class="iconfont" style= 'color: #56a8e5'>&#xe669;</span>
<a href="https://member.ysjszk.com:8888/" >会议系统</a>
</li>
<li >
<span class="iconfont" style= 'color: #f6d91a;font-size:25px'>&#xe6dc;</span>
<a href="#">评奖系统</a>
</li>
<li >
<span class="iconfont" style= 'color: #3ba1ea'>&#xe623;</span>
<a href="#">项目申报系统</a>
</li>

</div>
</div>
</template>
<script>
export default {
  name: 'appA'
}
</script>
<style scoped lang="less">
@media screen and (max-width:767px) {
  .w {
    z-index:55;
    width: 80vw!important;
    //margin-left:
    //margin:calc(var(--w)*2.2) auto!important;
    top:calc(var(--xd--fontsize)*18)!important;
    //margin:0 auto;
    margin-left:calc(var(--xd--fontsize)*21.7)!important;
  }
  .bottom li {

    float: left;
    margin:0 calc(var(--xd--fontsize)*.49)!important;
  }
  .iconfont {
    display:block;
    width: calc(var(--xd--fontsize)*2.2);
    height: calc(var(--xd--fontsize)*2.2);
    padding-top:calc(var(--xd--fontsize)*.5);
    font-size:calc(var(--xd--fontsize));
    border:2px solid #7ab7e3;
    border-radius: 50%;
    margin:0 auto;
  }

}



.iconfont {
  display:block;
  padding-top:calc(var(--xd--fontsize)*.5);
  font-size:calc(var(--xd--fontsize));
}
.w{
  display: none;
  --w:  calc(var(--xd--fontsize)*10);
  width: 11vw;
  //height: 500px;
  margin:0 auto;
  //margin-bottom:calc(var(--w)*.1);
  // padding:0 calc(var(--w)*.05) ;
  border-radius: var(--y-com-radius0);
  position: relative;
  top: calc(var(--w)*-1.7);
  left:calc(var(--w)*-2);
  background-color: #56a8e5;
}
.up{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1.5px solid rgb(194, 193, 193);
  padding: 0 calc(var(--w) * .1);
  font-size:calc(var(--xd--fontsize) * .7);
/* margin:0 calc(var(--w) * .25) ; */
}
.l1{
  padding: calc(var(--xd--fontsize) * .5) 0;
  /* margin-left: calc(var(--w) * .25); */
  font-weight: 700;
  border-bottom: 4px solid #3e6db5;
}
.bottom{
  //display: flex;
  //justify-content: space-between;
  padding:calc(var(--w)*.06) calc(var(--w)*.05) ;
  &>li{
    //justify-content: space-between;
    font-size:calc(var(--w)*.065) ;
    border-radius: var(--y-com-radius0);
    margin:0 calc(var(--w)*.01);
    transition: all .25s;
    //font-size:calc(var(--w) * 0.25);
    &>a{
      text-align: center;
      transition: all .25s;
      display:block;
      width: 100%;
      color: #000;
      padding: 1.6vw 0;
    }
  }

  &>li:hover a{
    // background-color: var(--y-com--theme);
    color: #3ba1ea !important;
  }
}
</style>
