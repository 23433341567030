import { createStore } from 'vuex'

export default createStore({
  state: {
    partId:null
  },
  getters: {
  },
  mutations: {
    setPartId(state,partId) {
      state.partId=partId
      // localStorage.setItem("partId",JSON.stringify(partId))

    },
  },
  actions: {
  },
  modules: {
  }
})
