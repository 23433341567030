<template>
<teleport to='body' >
<template v-if="mask">
<transition name="y-com-into2">
<div class="y-com-loading" v-show="show">
<transition name="y-com-into2">
<div class="y-com-load-box" theme1 v-show="show">
<YcomLogo>
<div class="y-com-loading-content" y-flexX theme>
<slot>加载中</slot>
</div>
</YcomLogo>
</div>
</transition>
</div>
</transition>
</template>
<template v-if="!mask">
<transition name="y-com-into2">
<div class="y-com-load-box" theme1  v-show="show">
<YcomLogo>
<div class="y-com-loading-content" y-flexX theme>
<slot>加载中</slot>
</div>
</YcomLogo>
</div>
</transition>
</template>
</teleport>
</template>

<script>
import { ref } from '@vue/reactivity'
import YcomLogo from '../YcomLogo/YcomLogo.vue'
import { onMounted, onUnmounted } from '@vue/runtime-core'
export default {
  name: 'YcomLoading',
  setup () {
    const show = ref(false)
    const mask = ref(true)

    function chageShow (e) {
      show.value = e.detail[0]
      if (e.detail.length === 2) mask.value = e.detail[1]
    }
    onMounted(() => {
      window.$Ycom.$on('y-com-loading-chageShow', chageShow)
    })
    onUnmounted(() => {
      window.$Ycom.$c('y-com-loading-chageShow', chageShow)
    })
    return {
      show,
      mask
    }
  },
  components: { YcomLogo }
}
</script>

<style scoped>
.y-com-loading{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color:  rgba(0, 0, 0, 0.505);
  backdrop-filter: blur(10px);
}
.y-com-load-box{
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 20vw;
  height: 20vw;
  padding: 4vw;
  box-sizing: border-box;
  border-radius: 1vw;
  
  box-shadow: var(--y-com-box-shadow);
  
}
.y-com-loading-content{
  width: 110%;
  height: 110%;
  flex-shrink: 0;
  border-radius: 50%;
  
  opacity: .85;
  font-size: var(--y-com-fontSize-Big2) !important;
  user-select: none;
}
</style>
